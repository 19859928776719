@import '../../styles/variables';

header {
  font-family: "roboto", sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 20px;
  margin: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .text {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      color: $second-color;
      font-size: 50px;
      margin-bottom: 10px;
    }

    p {
      font-weight: 600;
      line-height: 1.5rem;
      margin: 5px auto 10px;
    }
    

    .fa-facebook {
      color: grey;
    }
  }

  .fa-solid {
    color: $second-color;
  }

  .link {
    min-height: 40vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .phone,
    .mail {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .phone-link {
      text-decoration: none;
      color: white;
      text-shadow:
        1px 0 #240f00,
        -1px 0 #240f00,
        0 1px #240f00,
        0 -1px #240f00;
      transition:
        all 0.2s ease,
        visibility 0s;
      background: $main-color;
      border-radius: 60px;
      padding: 10px;
    }

    .mail-link {
      text-decoration: none;
      color: #240f00;
      font-size: 15px;
    }
  }
}

@media (max-width: 767px) {
  header {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    border-bottom: 2px solid $main-color;

    .text {
      min-height: 20px;
      h1 {        
        font-size: 25px;
      }
    }

    .fa-facebook {
      font-size: 21px;
      margin-bottom: 1rem;
    }

    .phone {
      font-size: 30px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  header {
    .text {
      min-height: auto;
      margin-top: -30px;
      h1 {
        font-size: 45px;
      }
    }
  }
}