@import "../../styles/variables";

.tarifs-s {
  background-image: url('./images/serrure-desktop.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px 60px;  
  min-height: 80vh;
  line-height: 2rem;
  font-family: "roboto", sans-serif;
  color: white;
  text-shadow: 1px 1px 2px black;

  h2 {
    color: $third-color;
    font-size: 40px;
  }

  .note {
    font-style: italic;
    color: $second-color;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 10px 0;
  }
  .tarif-item {
    display: flex;
    justify-content: space-between;
  }
  .tarif-description {
    flex: 1;
  }
  .tarif-price {
    font-weight: bold;
    margin-left: 20px;
    padding-right: 40%;
  }
}

@media (max-width: 767px) {
  .tarifs-s {
    background-image: url('./images/serrure-mobile.webp');
    background-position: 75%;
    padding: 10px;

    h2 {
      font-size: 20px;
    }

    .tarif-price {
      padding: 0;
    }
  }
}