@import "../../styles/variables";

.mentionsLegales {
  background-image: url('./images/justice-desktop.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  
  font-size: 1.2rem;  

  .mentions-legales-container {
    background-color: rgb(0, 0, 0, 0.6);
    padding: 20px 10px;
  }

  h2 {
    color: $third-color;
    font-size: 40px;
    font-weight: 900;
    text-shadow: 1px 1px 2px black;
  }

  h3 {
    margin-left: 20%;
    color: $main-color;
    font-size: 25px;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;
  }

  h4 {
    font-size: 15px;
    margin-left: 20%;
    text-align: left;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: white;
  }

  p {
    margin: 0 10% 0 20%;
    margin-top: 0;
    text-align: left;
    line-height: 2rem;
    color: white;

  }

  span {
    font-weight: 600;
  }

  .adresse {
    font-style: italic;
    font-weight: normal;
  }
}

@media (max-width: 767px) {
    .mentionsLegales {
        font-size: 1rem;
        background-position: 41%;
        background-image: url('./images/justice-mobile.webp');

        .mentions-legales-container {
          margin-top: 20px;
          padding-top: 0;
        }
        h2 {
          padding-top: 20px;
            font-size: 25px;
        }
        p {
            margin: 0 5px;
        }
        h3 {
            font-size: 21px;
            margin: 20px 5px 10px;
        }
        h4 {
            font-size: 18px;
            margin: 10px 5px 0px;
        }

    }
}