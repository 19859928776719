@import "../../styles/variables";

.home {
  width: 95%;
  margin: auto;
  padding: 20px 0;

  section {
    background-color: white;
    border-radius: 20px;
    padding: 1px 0 20px 0;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.3));
  }

  h2 {
    font-size: 40px;
    font-family: "roboto", sans-serif;
    color: $third-color;
    text-shadow: 1px 1px 2px black;
    text-decoration: underline;
  }

  i {
    text-shadow: 1px 1px 2px black;
  }
  
  .map {
    height: 400px;
    width: 98%;
    margin: auto;
    border-radius: 10px;
  }

  .open-hours {
    position: relative;
    margin: 20px 0;
    .fa-clock {
      position: absolute;
      left: 20%;
      top: 50px;
      font-size: 110px;
      margin-right: 10%;
      color: $main-color;
    }
    ul {
      list-style-type: none;
      text-align: left;
      display: inline-block;
      width: 30%;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      .hours-item {
        display: flex;
        justify-content: space-between;

        .hours {
          white-space: pre;
        }
      }
    }
    .note {
      font-style: italic;
      color: $second-color;
    }
  }

  .pay {
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    flex-wrap: wrap;

    .pay-item {
        display: flex;
        flex-direction: column;
        width: 50%;
        .fa-solid {
            color: $second-color;
            font-size: 3rem;
        }
    }
  }
}

@media (max-width: 767px) {
  .home {
    h2 {
      font-size: 25px;
    }

    section {
      margin-bottom: 5%;  
    }

    .open-hours {
        position: relative;
        .fa-clock {
          position: absolute;
          left: 2%;
          top: 50px;
          font-size: 70px;
          margin-right: 10%;
          color: $main-color;
        }
        ul {
          width: 60%;
          padding-left: 15%;       
        }
    }

    .pay {
        margin-left: -60px
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .home {
       .open-hours {
        position: relative;
        .fa-clock {
          position: absolute;
          left: 10%;
          top: 70px;
          font-size: 70px;
          margin-right: 10%;
          color: $main-color;
        }
        ul {
          width: 50%;          
        }
        
    } 
    .pay {
          margin-left: -60px;
        }
    }
    
}
